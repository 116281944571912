import Particle from './components/Particles'
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import './App.css';

function App() {
  return (
      <div className="flex flex-col items-center justify-center h-screen px-4">
        <Particle id="particles"/>
        <h4 className="text-3xl font-bold text-stone-50 mb-4 text-center absolute top-0 left-0 p-5">Explore
          Verge</h4>
        <div className="flex space-x-4 absolute top-0 right-0 p-5">
          <a href="https://www.linkedin.com/company/exploreverge" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48">
              <path fill="#0288D1"
                    d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path>
              <path fill="#FFF"
                    d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
            </svg>
          </a>
        </div>
        <h1 className="text-7xl font-bold text-stone-50 mb-4 text-center">Launching Soon</h1>
        <FlipClockCountdown
            to={new Date('March 22, 2024 00:00:00').getTime()}
            labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
            labelStyle={{color: 'white', fontSize: 10, fontWeight: 500, textTransform: 'uppercase'}}
            digitBlockStyle={{color: 'white', width: 30, height: 50, fontSize: 30}}
            dividerStyle={{color: 'black', height: 2}}
            separatorStyle={{color: 'white', size: '6px'}}
            duration={0.5}
            hideOnComplete={true}
        >
          WELCOME TO EXPLORE VERGE!
        </FlipClockCountdown>
        <p className="text-stone-50 text-lg mb-8 text-center pt-5">"Excitement is brewing! Stay tuned for our upcoming
          website launch, where innovation meets inspiration."</p>
        <div className="flex space-x-4">
          <a href="https://forms.gle/cHirvpBhQuqqwXpV9" target="_blank" rel="noopener noreferrer"
             className="text-2xl bg-blue-500 hover:bg-blue-600 text-white py-3 px-8 rounded-full shadow-lg transition duration-300">Join
            Us</a>
        </div>
      </div>
  );
}

export default App;
